body{
  background-image: url(./assets/Backround-lightBlue.png);
  background-attachment: fixed;
}

.mainContainer{
  background-color: #bbdefb;
  z-index: -3;
  overflow: auto;
}

.sidebar{
  background-color: #03a9f4;
  width: 12vw;
  position: fixed;
  top: 75px;
  overflow: auto;
  text-decoration: none;
  margin: 0;
}

.sidebar a{
  text-decoration: none;
}

.header{
  background-color: #03a9f4;
  height: 70px;
  margin: 0 0 5px 0;
  padding: 0;
}

.header h1{
  position: relative;
  bottom: -35px;
  left: 160px;
  width: 300px;
  margin: 0;
  padding: 0;
}

.about-me .right{
  float: right;
  margin: 0 0 0 10px;
}

.about-me .left{
  float: left;
  margin: 0 10px 0 0;
}

.portfolio {
  
}