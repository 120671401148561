.headshot{
    float: left;
    margin: 0 10px 10px 0;
    border-radius: 50%;
}

.testDiv{
    overflow: auto;
    
}

.testDiv h2{
    font-size: 40px;
    text-align: center;
    position: relative;
    top: 10%;
    margin: 10px;
}

p {
    font-size: 18px;
}

.aboutImage {
    display: flex;
    justify-content: space-evenly;

}

.aboutImage img{
    border: 5px solid #0d47a1;
}